
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.feast-files {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 5px 0 0;
  }

  &__image {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }
}
